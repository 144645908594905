import { Suspense, lazy } from "react";
// import RouteConfig from "./Route/config";
import { Route, Routes, useLocation } from "react-router-dom";
import request from '@/utils/request';
import { isWechatBrowser } from '@/utils/utils';
import React from "react";
// import {  Loading } from "react-vant";
import './App.css'
import { useRequest } from "ahooks";
import { fetchWXConfig } from '@/pages/Home/api'
import { toUnicode } from "punycode";

const Home = lazy(() => import('./pages/Home'));
const Questions = lazy(() => import('./pages/Questions/record'));
const Collect = lazy(() => import('./pages/Home/collect'));
const Temp = lazy(() => import("./pages/Questions/temp"));
const CoinNotes = lazy(() => import('./pages/CoinNotes'));
const AnswerRes = lazy(() => import('./pages/AnswerRes'));

const App = () => {

  const LoadingView = "";
  const { run: queryWXConfig } = useRequest((config_url: string) => fetchWXConfig({ config_url }), {
    manual: true,
    onSuccess: resData => {
      if (resData?.data) {
        window.wx.config(resData.data);
      }
    }
  })
  const location = useLocation();
  return (
    <Routes>
      <Route
        path="/roll"
        element={
          <Suspense fallback={LoadingView}>
            <Home />
          </Suspense>
        }
      ></Route>
      <Route
        path="/collect"
        element={
          <Suspense fallback={LoadingView}>
            <Collect />
          </Suspense>
        }
      ></Route>
      <Route
        path="/questions"
        element={
          <Suspense fallback={LoadingView}>
            <Questions />
          </Suspense>
        }
      ></Route>
      <Route
        path="/CoinNotes"
        element={
          <Suspense fallback={LoadingView}>
            <CoinNotes />
          </Suspense>
        }
      ></Route>
      <Route
        path="/AnswerRes/:day_id"
        element={
          <Suspense fallback={LoadingView}>
            <AnswerRes />
          </Suspense>
        }
      ></Route>
      {/* <Route
        path="/*"
        element={
          <Suspense fallback={LoadingView}>
            <Empty image="error" description="页面未找到" />
          </Suspense>
        }
      ></Route> */}
      {/* <Route
        path="/questions/all/:grade_id"
        element={
          <Suspense fallback={LoadingView}>
            <Temp />
          </Suspense>
        }
      >
      </Route> */}
    </Routes>
  );
};

export default App;
